// components
import { InnerHtml } from '../../../InnerHtml/inner-html';
import { Image } from '../../../Image/Image';

// utils
import { decodingContent } from '../../../../utils/decodingContent';
import { previewId } from '../../../../utils/preview-id';

interface TileContentProps {
  subheadline: string | null;
  headline: string | null;
  link: any | null;
  text: string | null;
  journeyType: string | null;
  isFullwidth: string;
}

function TileContent({
  subheadline = null,
  headline = null,
  link = null,
  text = null,
  journeyType = null,
  isFullwidth = '',
}: Readonly<TileContentProps>) {
  return (
    <div className="c-tile-content__text">
      {subheadline && (
        <span {...previewId('#st_subheadline')} className="category">
          {decodingContent(subheadline)}
        </span>
      )}
      {headline && <InnerHtml as="h3" content={headline} previewId="#st_headline" />}
      {text && <InnerHtml as="p" content={text} previewId="#st_text" />}
      {link && <span className="link">{decodingContent(link.text)}</span>}
      {journeyType && !isFullwidth && (
        <div className="marker">
          <Image src={`/icons/icon-${journeyType}.svg`} alt={journeyType} staticImage />
        </div>
      )}
    </div>
  );
}

export default TileContent;
