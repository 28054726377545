import OrientationTile from './OrientationTile';
import QuadraticTile from './QuadraticTile';
import JobsTile from './jobs-tile';
import LocatorTile from './LocatorTile';
import { ShowroomLocatorTile } from './showroom-locator-tile';

const tileTypes = {
  vertical_tile: OrientationTile,
  horizontal_tile: OrientationTile,
  quadratic_general_tile: QuadraticTile,
  quadratic_download_tile: QuadraticTile,
  quadratic_locator_tile: QuadraticTile,
  jobs_tile: JobsTile,
  locator_tile: LocatorTile,
  showroom_locator_tile: ShowroomLocatorTile,
};

export default tileTypes;
