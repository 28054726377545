import React from 'react';
import tileTypes from './tileTypes';

function TileRenderer({ ...data }) {
  const { type } = data;
  const Tile = tileTypes[type];

  return Tile ? <Tile {...data} /> : null;
}

export default TileRenderer;
