import { useContext } from 'react';
import { Notification } from '@geberit/gdds';

// styles
import styles from './locator-tile.module.scss';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { SearchContainer, SearchContext } from 'components/ContentElementsGdds/locator/search';

// utils
import { useUCConsent } from 'features/cookie-consent';
import { GoogleMapProvider } from 'components/ContentElementsGdds/locator/overview-page/google-map-provider';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { classNameBuilder } from 'utils/classNameBuilder';

export function ShowroomLocatorTileContent({
  headline,
  subheadline,
  text,
}: Readonly<{
  subheadline: string;
  headline: string;
  text: string;
}>) {
  const consent = useUCConsent();
  const locatorConfiguration = useLocatorConfiguration();
  const { error, resetError } = useContext(SearchContext);
  const showZipEntry = consent.Comfort === true;

  return (
    <>
      <div className={classNameBuilder('c-tile-content__text', styles.withOverflow)}>
        <InnerHtml as="strong" content={subheadline} previewId="#st_subheadline" />
        <InnerHtml as="h3" content={headline} previewId="#st_headline" />
        <InnerHtml as="p" content={text} previewId="#st_text" />
        <GoogleMapProvider>{showZipEntry && <SearchContainer />}</GoogleMapProvider>
      </div>

      {error && (
        <Notification
          type="broadcast"
          text={locatorConfiguration?.consent?.geoLocationFailureNote ?? ''}
          buttonStyleType="flatInverted"
          showCloseButton
          positionTop={0}
          onClick={resetError}
          className="notification"
        />
      )}
    </>
  );
}
